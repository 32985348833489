import { Link } from "gatsby"
//import PropTypes from "prop-types"
import React from "react"
 
class Header extends React.Component{
  //const Sidebar = ({ siteTitle }) => (  
  constructor(){
    super();

    this.state = {
      showHeaderElements:false,			
      languageID:0
    }
  }

  toggleHeaderElements = () => {
    if(this.state.showHeaderElements === true){	
      localStorage.setItem('showHeaderElements', false);
      this.setState({showHeaderElements:false});
    }else{			
      localStorage.setItem('showHeaderElements', true);
      this.setState({showHeaderElements:true});
    }
  };

  render(){
		return(  
      <nav className={this.state.showHeaderElements === false ? 'navbar navbar-expand-lg navbar-light bg-light' : 'navbar navbar-expand-lg navbar-light bg-light collapseHeight'}>
        <div className="container-fluid">
          <div className="main-name">Fabien Naté</div>
          <button onClick={this.toggleHeaderElements.bind()} className="btn btn-dark d-inline-block d-lg-none ml-auto" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded={this.state.showHeaderElements === false ? 'false' : 'true'} aria-label="Toggle navigation">
            <svg className="svg-inline--fa fa-align-justify fa-w-14" aria-hidden="true" data-prefix="fas" data-icon="align-justify" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" data-fa-i2svg=""><path fill="currentColor" d="M0 84V44c0-8.837 7.163-16 16-16h416c8.837 0 16 7.163 16 16v40c0 8.837-7.163 16-16 16H16c-8.837 0-16-7.163-16-16zm16 144h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16zm0 256h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16zm0-128h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16z"></path></svg>
          </button>
          <div className={this.state.showHeaderElements === false ? 'collapse navbar-collapse' : 'collapse navbar-collapse show'} id="navbarSupportedContent">
            <ul className="nav navbar-nav ml-auto">
              <li className="nav-item">
                <Link to="/" className='nav-link'>{ this.props.site.author}</Link>
              </li>
              <li className="nav-item">
                <Link to={ this.props.books.nodes[0].link} className='nav-link'>{ this.props.books.nodes[0].book}</Link>
              </li>
              <li className="nav-item">
                <Link to={ this.props.books.nodes[1].link} className='nav-link'>{ this.props.books.nodes[1].book}</Link>
              </li>
              <li className="nav-item">
                <Link to={ this.props.books.nodes[2].link} className='nav-link'>{ this.props.books.nodes[2].book}</Link>
              </li>              
            </ul>
          </div>
        </div>
      </nav>
    )
  }
};

/*
Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}*/

export default Header
