/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import Sidebar from "./sidebar"
import "./layout.css"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title,
          description,
          author,
        }
      },
      allBooksJson {
        nodes {
          book
          pages
          link
          pageInfo {
            node {
              link
              poemTitle
            }
          }
        }
      },
    }
  `)

  return (
    <>
    <nav>
      <div className="wrapper">
        <Sidebar siteTitle={data.site.siteMetadata.title} author={data.site.siteMetadata.author} books={data.allBooksJson} />      
        <div id="content" className="content">
          <Header site={data.site.siteMetadata} books={data.allBooksJson}/>
          <div className="row">          
            <main role="main" className="col-md-9 ml-sm-auto col-lg-10 pt-3 px-4">
              {children}              
            </main>
          </div>
        </div>        
      </div>
      <footer className="footer">
          <div className="container">
            <span className="text-muted">
              ©<a href="mailto:fabiennatenm@gmail.com" target="_blank" rel="noopener noreferrer">Fabien Naté Foundation</a> {new Date().getFullYear()}. All Rights Reserved. <a href="/apropos">À Propos</a> | <a href="/contact">Contact</a> | <a href="/faq">FAQs</a> | <a href="/legal">Mentions Légales</a> 
              {` `}
            </span>
          </div>
        </footer>
        </nav>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
