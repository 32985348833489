import { Link } from "gatsby"
//import PropTypes from "prop-types"
import React from "react"

class Sidebar extends React.Component{
//const Sidebar = ({ siteTitle }) => (  
  constructor(){
		super();

		this.state = {
      showElements:false,
      showSentierElements:false,
      showPleadeElements:false,
			showUserInfoList:false,			
			languageID:0,
			shownavtogglelist:false
		}
  }

  getbookandtoggledropdown = (book) =>{
    //let element = book;  
    switch(book){
      case 'bananes':
        this.togglebananes();
        break;
      case 'sentier':
        this.togglesentier();
        break;
      case 'pleade':
        this.togglepleade();
        break;
      default:
        break;
    }
  };

  togglebananes = () => {
    if(this.state.showElements === true){	
      localStorage.setItem('showElements', false);
      this.setState({showElements:false});
    }else{			
      localStorage.setItem('showElements', true);
      this.setState({showElements:true});
    }

		//this.props.getLanguage(language);
  };
  
  togglesentier = () => {
    if(this.state.showSentierElements === true){	
      localStorage.setItem('showSentierElements', false);
      this.setState({showSentierElements:false});
    }else{			
      localStorage.setItem('showSentierElements', true);
      this.setState({showSentierElements:true});
    }
  };
  
  togglepleade = () => {
    if(this.state.showPleadeElements === true){	
      localStorage.setItem('showPleadeElements', false);
      this.setState({showPleadeElements:false});
    }else{			
      localStorage.setItem('showPleadeElements', true);
      this.setState({showPleadeElements:true});
    }
	};
  
  render(){
		return(
      <nav id="sidebar">
        <div className="sidebar-header">
            <h3>Poèmes</h3>
        </div>
        <ul className="list-unstyled components">
          <p></p>
          <li className="active">
            <Link to="/" className='nav-link'>{ this.props.author }</Link>
          </li>
          <li>
            <a href="#" onClick={this.getbookandtoggledropdown.bind(this, 'bananes')} data-toggle="collapse" aria-expanded={this.state.showElements === false ? 'false' : 'true'} className={this.state.showElements === false ? 'dropdown-toggle collapsed' : 'dropdown-toggle'}>{ this.props.books.nodes[0].book }</a>
            <ul className={this.state.showElements === false ? 'collapse list-unstyled' : 'collapse list-unstyled show'} id="homeSubmenu">
              {this.props.books.nodes[0].pageInfo.map(({ node }, index) => (
                <li key={index}>
                <Link to={ node.link } className='nav-link'>{ node.poemTitle }</Link>
              </li>
              ))}              
            </ul>
          </li>
          <li>
            <a href="#" onClick={this.getbookandtoggledropdown.bind(this, 'sentier')} data-toggle="collapse" aria-expanded={this.state.showSentierElements === false ? 'false' : 'true'} className={this.state.showSentierElements === false ? 'dropdown-toggle collapsed' : 'dropdown-toggle'}>{ this.props.books.nodes[1].book }</a>
            <ul className={this.state.showSentierElements === false ? 'collapse list-unstyled' : 'collapse list-unstyled show'} id="sentier">
            {this.props.books.nodes[1].pageInfo.map(({ node }, index) => (
                <li key={index}>
                <Link to={ node.link } className='nav-link'>{ node.poemTitle }</Link>
              </li>
              ))}  
            </ul>
          </li>
          <li>
            <a href="#" onClick={this.getbookandtoggledropdown.bind(this, 'pleade')} data-toggle="collapse" aria-expanded={this.state.showPleadeElements === false ? 'false' : 'true'} className={this.state.showPleadeElements === false ? 'dropdown-toggle collapsed' : 'dropdown-toggle'}>{ this.props.books.nodes[2].book }</a>
            <ul className={this.state.showPleadeElements === false ? 'collapse list-unstyled' : 'collapse list-unstyled show'} id="sentier">
            {this.props.books.nodes[2].pageInfo.map(({ node }, index) => (
                <li key={index}>
                <Link to={ node.link } className='nav-link'>{ node.poemTitle }</Link>
              </li>
              ))}  
            </ul>
          </li>
        </ul>
        <ul className="list-unstyled CTAs">
            <li>
                <a href="/faq/" className="download">Foire aux questions</a>
            </li>
            {
            /*
            <li>
                <a href="https://bootstrapious.com/tutorial/files/sidebar.zip" className="article">Back to article</a>
            </li>
            */
            }
        </ul>
      </nav>
    )
  }   
};

/*
Sidebar.propTypes = {
  siteTitle: PropTypes.string,
}

Sidebar.defaultProps = {
  siteTitle: ``,
}*/

export default Sidebar
